<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';
import {
	useChartInterval,
	useChangeChartInterval,
	type ChartInterval,
} from '@/src/features/terminal';
import { useSidebarStore } from '~/src/widgets/terminal/store';

const changeChartInterval = useChangeChartInterval();
const currentInterval = useChartInterval();

const allButtons = [
	// { value: '1sec', text: '1 сек' },
	{ value: '5sec', text: '5 сек' },
	{ value: '15sec', text: '15 сек' },
	{ value: '30sec', text: '30 сек' },
	{ value: '1min', text: '1 мин' },
	{ value: '5min', text: '5 мин' },
	{ value: '15min', text: '15 мин' },
	{ value: '30min', text: '30 мин' },
	{ value: '1h', text: '1 час' },
	{ value: '3h', text: '3 часа' },
	{ value: '1d', text: '1 день' },
	// { value: '30d', text: '30 дней' },
];

const isOdd = computed(() => allButtons.length % 2 !== 0);
const sidebarStore = useSidebarStore();
const onChangeChartIntervalClick = (value: ChartInterval) => {
	changeChartInterval(value);
	sidebarStore.closeDrawer();
};
</script>

<template>
	<div class="grid grid-cols-2 gap-4">
		<BaseButton
			v-for="(button, index) in allButtons"
			:key="index"
			color="alternative-dark"
			size="sm"
			outline
			filled-icon
			:button-text="button.text"
			:class="{
				'!bg-gray-700 !text-white': currentInterval === button.value,
				'last:col-start-1 last:col-end-3': isOdd && index === allButtons.length - 1,
			}"
			@click="onChangeChartIntervalClick(button.value)"
		/>
	</div>
</template>
